import React from "react";
import { graphql, PageProps } from "gatsby";
import styled from "styled-components";

import { Query } from "@graphql-types";
import SEO from "@shared/seo";
import { PageContext } from "@util/types";
import FooterSections from "@shared/footer/footerSections";
import { Container, PageWidth, Section } from "@util/standard";
import { BlocksContent, Image, Link } from "@global";
import { colorsRGB, LAPTOP_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";
import { useHandleQuery } from "@api";

interface Props extends PageProps {
  data: Query;
  pageContext: PageContext;
}

export const ArticleSection = styled(Section)`
  img {
    border-radius: 10px;
  }
`;

export const PageWidthStyled = styled(PageWidth)`
  max-width: 900px;
  width: 100%;
  flex-flow: column nowrap;

  @media only screen and (max-width: ${LAPTOP_BREAKPOINT}px) {
    max-width: 750px;
  }
`;

export const Headerwrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;

  border-bottom: 1.5px solid ${colorsRGB.main(0.3)};
  padding-bottom: 30px;
  margin-bottom: 50px;

  h1 {
    margin-bottom: 0;
    margin-right: 80px;
  }

  a{
    text-decoration: underline;
    white-space: nowrap;
    &:hover{
      opacity: 0.5;
    }
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-flow: column nowrap;
    align-items: flex-start;

    h1{
      margin-right: 0;
      margin-bottom: 20px;
    }
    
  }
`;

export default function BlogInnerTemplate({ data, pageContext }: Props) {
  if (!data.sanityBlogInner) return null;

  const { title, seo, image, blockContent, pageFooter } = data.sanityBlogInner;
  const handles = useHandleQuery();

  return (
    <>
      <SEO seoData={seo} slug={pageContext.pagePath} />
      <ArticleSection>
        <PageWidthStyled>
          <Headerwrapper>
            <h1 className="h3">{title}</h1>
            <Link internalLink={handles.know} linkText="Back to Advice" />
          </Headerwrapper>
          {image &&
            <Container margin="0 0 75px" tabletMargin="0 0 50px">
              <Image data={image} />
            </Container>
          }
          <BlocksContent data={blockContent} />
        </PageWidthStyled>
      </ArticleSection>
      <FooterSections pageFooter={pageFooter} />
    </>
  );
}

export const query = graphql`
  query BlogInnerQuery($slug: String) {
    sanityBlogInner(slug: {current: {eq: $slug}}) {
      title
      seo {
        ...sanitySeo
      }
      publishedDate
      author
      image {
        ...sanityImageConstrained
      }
      blockContent {
        ...sanityBlockContent
      }
      pageFooter {
        ...sanityPageFooter
      }
    }
  }
`;
